import $ from 'jquery';

function tbxHandleScrolledHeader() {
	if ( $('.pseudo-body').hasClass('home') ) {
		if ( $(window).scrollTop() > 50 ) {
			//$('html').addClass('header-scrolled');
			//$('.header-wrap').addClass('scrolled');
		}
		else {
			//$('html').removeClass('header-scrolled');
			//$('.header-wrap').removeClass('scrolled');
		}
	}
	else {
		if ( $(window).scrollTop() > 50 ) {
			//$('html').addClass('header-scrolled');
			//$('.header-wrap').addClass('scrolled');
		}
		else {
			//$('html').removeClass('header-scrolled');
			//$('.header-wrap').removeClass('scrolled');
		}
	}
}

export default tbxHandleScrolledHeader;